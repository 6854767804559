import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const MainContent = styled.main<PropsWithTheme<{}>>`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: 4.5rem;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: 5rem;
  }
`;
