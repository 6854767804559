import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const DollarIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.8989 20.1824L24.6282 3.00725C24.2616 2.71129 23.7382 2.71129 23.3717 3.00725L2.10094 20.1824C1.73409 20.4786 1.94355 21.0714 2.41505 21.0714H7.2307V44.5C7.2307 45.0523 7.67841 45.5 8.2307 45.5H17.4615C18.0138 45.5 18.4615 45.0523 18.4615 44.5V28.7857H29.5384V44.5C29.5384 45.0523 29.9861 45.5 30.5384 45.5H39.7692C40.3214 45.5 40.7692 45.0523 40.7692 44.5V21.0714H45.5848C46.0563 21.0714 46.2658 20.4786 45.8989 20.1824Z"
      style={{ fill: SAGE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7435 2.22921C23.4766 1.6373 24.5233 1.6373 25.2564 2.22921L46.5272 19.4044C47.6277 20.293 46.9994 22.0714 45.5848 22.0714H41.7692V44.5C41.7692 45.6046 40.8738 46.5 39.7692 46.5H30.5384C29.4339 46.5 28.5384 45.6046 28.5384 44.5V29.7857H19.4615V44.5C19.4615 45.6046 18.5661 46.5 17.4615 46.5H8.23073C7.12616 46.5 6.23073 45.6046 6.23073 44.5V22.0714H2.41508C1.00056 22.0714 0.372206 20.293 1.47274 19.4044L22.7435 2.22921ZM44.1697 20.0714L24 3.78528L3.83021 20.0714H8.23073V44.5H17.4615V28.7857C17.4615 28.2334 17.9092 27.7857 18.4615 27.7857H29.5384C30.0907 27.7857 30.5384 28.2334 30.5384 28.7857V44.5H39.7692V20.0714H44.1697ZM45.2707 20.9604C45.2704 20.9602 45.2701 20.9599 45.2697 20.9596Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      d="M44 19C44 25.6274 38.6274 31 32 31C25.3726 31 20 25.6274 20 19C20 12.3726 25.3726 7 32 7C38.6274 7 44 12.3726 44 19Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 8C25.9249 8 21 12.9249 21 19C21 25.0751 25.9249 30 32 30C38.0751 30 43 25.0751 43 19C43 12.9249 38.0751 8 32 8ZM19 19C19 11.8203 24.8203 6 32 6C39.1797 6 45 11.8203 45 19C45 26.1797 39.1797 32 32 32C24.8203 32 19 26.1797 19 19Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      d="M32.0002 11.5834C32.5524 11.5834 33.0002 12.0311 33.0002 12.5834V13.2576C33.618 13.4059 34.1903 13.6875 34.6618 14.0714C35.3816 14.6575 35.9168 15.5311 35.9168 16.5695C35.9168 17.1218 35.4691 17.5695 34.9168 17.5695C34.3645 17.5695 33.9168 17.1218 33.9168 16.5695C33.9168 16.2549 33.7562 15.9132 33.399 15.6223C33.0402 15.3302 32.5345 15.1389 32.0002 15.1389C30.8192 15.1389 30.0835 15.9494 30.0835 16.5695C30.0835 17.0347 30.2229 17.3517 30.4446 17.5596C30.6726 17.7735 31.1249 18 32.0002 18C33.2063 18 34.2126 18.3132 34.9229 18.9777C35.6402 19.6487 35.9168 20.5481 35.9168 21.4306C35.9168 22.466 35.3806 23.339 34.6627 23.9249C34.1915 24.3094 33.6189 24.5927 33.0002 24.7419V25.4167C33.0002 25.969 32.5524 26.4167 32.0002 26.4167C31.4479 26.4167 31.0002 25.969 31.0002 25.4167V24.7383C29.4598 24.361 28.0835 23.1405 28.0835 21.4306C28.0835 20.8783 28.5312 20.4306 29.0835 20.4306C29.6358 20.4306 30.0835 20.8783 30.0835 21.4306C30.0835 22.0445 30.8216 22.8612 32.0002 22.8612H32.0013C32.5333 22.8609 33.0386 22.6689 33.3981 22.3755C33.7572 22.0824 33.9168 21.7401 33.9168 21.4306C33.9168 20.9601 33.7768 20.6442 33.5566 20.4382C33.3293 20.2256 32.8774 20 32.0002 20C30.7921 20 29.786 19.6838 29.0766 19.0186C28.3608 18.3474 28.0835 17.4492 28.0835 16.5695C28.0835 14.8517 29.4608 13.6366 31.0002 13.2613V12.5834C31.0002 12.0311 31.4479 11.5834 32.0002 11.5834Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
