import React from 'react';
import { Ankle } from '../components/ankle';
import { FaqSection } from '../components/faq';
import { Layout } from '../components/layout';
import { faqWithAnswers } from '../data/content/faq';

export const Faq = () => (
  <Layout>
    <FaqSection faqData={faqWithAnswers} />
    <Ankle />
  </Layout>
);
export default Faq;
