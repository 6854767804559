import { useTrackingInstance } from '@rategravity/1pt-lib';
import { Footer, Header, LandingFooter, NavBar } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import { useLocation } from '@reach/router';
import React, { Fragment, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { my, www } from '../../modules/navigation';
import { MainContent } from './elements';

const LandingPageAppWrapperStyle = createOwnUpStyle({
  margin: 'auto',
  maxWidth: '1280px',
  variants: {
    mediumAndDown: {
      paddingTop: '64px'
    }
  }
});

const LandingPageAppWrapper = createOwnUpComponent(OwnUpBox, LandingPageAppWrapperStyle);

const AppWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
`;

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const trackingInstance = useTrackingInstance();

  return (
    <Fragment>
      <NavBar {...{ www, my, isMainSite: true, trackingInstance }} />
      <MainContent>{children}</MainContent>
      <Footer {...{ www, isMainSite: true, trackingInstance }} />
    </Fragment>
  );
};

export const LayoutWithAppWrapper = ({ children }: PropsWithChildren<{}>) => (
  <Layout>
    <AppWrapper>{children}</AppWrapper>
  </Layout>
);

export const LandingPageLayout = ({ children }: PropsWithChildren<{}>) => {
  const { pathname, href } = useLocation();

  const trackingEvent = {
    hook: useTrackingInstance(),
    name: 'Click discover CTA',
    properties: {} // destination link will be filled in by Header
  };
  if (pathname === '/find-your-best-mortgage-mktg02/' && typeof window !== 'undefined') {
    const nerdWallet = new URLSearchParams(window.location.search).get('source') === 'NerdWallet';
    trackingEvent['name'] = `fybm2-cta-${nerdWallet ? 'nerdwallet' : 'default'}-menu`;
  }

  return (
    <React.Fragment>
      <LandingPageAppWrapper>
        <Header
          {...{
            page: pathname,
            href,
            mainSite: true,
            my,
            www,
            trackingEvent
          }}
        />
        {children}
      </LandingPageAppWrapper>
      <LandingFooter {...{ mainSite: true, www }} />
    </React.Fragment>
  );
};
