import React from 'react';
import { OWN_UP_FEE } from '../constants';

export const faqWithAnswers = [
  {
    question: 'What is Own Up?',
    answer: (
      <>
        Own Up is a technology company designed around people; not lenders. We offer simple online
        tools to help you find out if you&apos;ll be approved for a loan and how much you can
        borrow, while enabling you to compare lenders and actual pre-qualified loan offers from our
        trusted network. Because we streamline the origination process, lenders save money, which
        means they can offer you great terms. Own Up also gives every customer a dedicated mortgage
        expert who offers clear, unbiased guidance every step of the way.
      </>
    )
  },
  {
    question: 'So how are you able to save customers so much money?',
    answer: (
      <>
        The typical salesperson at a mortgage lender is paid a commission equal to 1.15% of the loan
        (that’s the industry average). Our system allows us to go directly to the lender and with
        everything they need without paying a salesperson. For our service, our lenders pay us a fee
        equal to just 0.{OWN_UP_FEE}% of the loan amount. Because we save them so much money, many
        of our lenders reduce their rates for our customers.
        <br />
        <br />
        Additionally, the Consumer Financial Protection Bureau estimates that rates can vary among
        lenders by as much as 0.5% — sometimes even inside a single organization. By shopping
        around, we’re able to save our customers time and money; in some cases, tens of thousands of
        dollars.
      </>
    )
  },
  {
    question: 'So how does Own Up make money?',
    answer: (
      <>
        If you choose a mortgage from one of our lenders, they pay us 0.{OWN_UP_FEE}% of the loan
        amount. That’s 1/3rd of what they pay their own salespeople, and the savings are passed on
        to our customers. If you don’t choose one of our lenders — but use our tools and guidance as
        reassurance or to negotiate a better rate from another lender — then we make nothing. But
        that’s okay, because we want happy customers who’ll recommend us to their friends.
      </>
    )
  },
  {
    question: 'So you’re not a lender or a bank?',
    answer: (
      <>
        That’s right. Own Up is a technology-enabled service designed to help customers evaluate
        lenders, choose the right mortgage, and ensure they’re getting fair terms — we do not
        provide financing. Instead, we match customers with top-rated lenders and help them compare
        offers and choose the loan that best suits their needs. We make it easy to lock in an
        interest rate, and we provide ongoing counsel until you close your loan.
      </>
    )
  },
  {
    question: 'Who are your customers?',
    answer: (
      <>
        Own Up is designed for anyone who is thinking about buying a home or refinancing a mortgage
        for any type of property. In fact, we think everyone should work with us, because there’s
        nothing to lose and lots to gain by getting multiple offers to ensure you’re getting fair
        terms. Even if you just use the loan offers from our lender network for reassurance that
        your existing lender is not overcharging you, we’re happy to help.
      </>
    )
  },
  {
    question: 'What kind of properties do your customers finance?',
    answer: (
      <>
        We’ve helped thousands of customers finance their first home, as well as people who are
        buying second homes and investment properties. We’ve got happy customers who own
        single-family homes, multi-family homes, condos, and townhouses.
      </>
    )
  },
  {
    question: 'So you can help with refinancing too?',
    answer: (
      <>
        Absolutely. We can help you refinance your mortgage to take cash out of your existing
        property or refinance into a different rate and term. We have lots of happy customers who’ve
        refinanced their property and saved tens of thousands of dollars.
      </>
    )
  },
  {
    question: 'Will working with Own Up affect my credit?',
    answer: (
      <>
        To check the rates and terms you qualify for, Own Up utilizes a soft credit inquiry that
        does NOT affect your credit score. We do NOT require you to provide your Social Security
        Number (SSN) to use our service.
      </>
    )
  },
  {
    question: 'Is my personal information secure?',
    answer: (
      <>
        The security of your data is Own Up’s highest priority. All information transmitted to and
        from our website is encrypted in accordance with industry standards while in transit and at
        rest on our servers. In addition, we do not collect sensitive personal information such as
        your social security number or your personal financial documents. We never share any of your
        personal information without your express consent and lenders with whom you do elect to
        share information can only access your data through our secure environment.
      </>
    )
  },
  {
    question: 'Who are Own Up participating lenders?',
    answer: (
      <>
        Own Up works with reputable and financially secure mortgage lenders. Each of our
        participating lenders undergoes a rigorous screening process and agrees to Own Up’s service
        level standards. In addition, we share all customer feedback and reviews with our lenders.
      </>
    )
  },
  {
    question: 'How much does Own Up cost?',
    answer: (
      <>
        Own Up is completely free to use. We are paid a fee by our lenders if you close your loan
        with them. Depending on your loan selection, lenders may charge customary third-party
        closing costs.
      </>
    )
  },
  {
    question: 'What are third-party closing costs?',
    answer: (
      <>
        Closing costs are the fees associated with closing your new mortgage. These typically
        include fees for an appraisal, title insurance, title search, transfer taxes, attorney,
        property taxes, homeowners insurance, and government recording fees. These fees vary
        depending on the transaction type and the location of the property. Should you complete an
        application with an Own Up lender partner, Own Up&rsquo;s lender partner will provide you
        with a Loan Estimate for your particular transaction.
      </>
    )
  },
  {
    question: 'How much of a down payment is required?',
    answer: (
      <>
        Own Up lenders offer a variety of down-payment options, including low down payment loan
        programs.
      </>
    )
  }
];
