import { useTrackingInstance } from '@rategravity/1pt-lib';
import { OwnUpExtraSmallHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import { DollarIcon } from '../../images/icons/reskin/dollar';
import { IconHeadingSection } from '../icon-heading-section';
import { GetStartedCta, MethodologyLink } from './elements';

export const Ankle = () => {
  const trackingInstance = useTrackingInstance();
  const handleCtaClick = () =>
    trackingInstance.track(`clickDiscoveryCTA`, { 'page-location': 'ankle' });
  return (
    <IconHeadingSection aria-labelledby="ankle-headline" icon={DollarIcon}>
      <OwnUpExtraSmallHeadlineBook variant="h2">
        <span id="ankle-headline">
          It pays to shop for the right loan. Whether buying or refinancing, our customers save an
          average of $27k over the life of their loan.
        </span>
      </OwnUpExtraSmallHeadlineBook>
      <MethodologyLink>How the savings are calculated</MethodologyLink>
      <GetStartedCta onClick={handleCtaClick} />
    </IconHeadingSection>
  );
};
